* {
    margin: 0;
    padding: 0;
    /* list-style: none; */
    /* border: 0; */
    font-size: 12px;
    font: inherit;
    /* font-weight: normal; */
    vertical-align: baseline;
}

/* ol,
ul,
li {
    list-style: none;
} */

a {
    text-decoration: none;
    -webkit-backface-visibility: hidden;
}

html,
body {
    width: 100%;
    font-family: "Arial", "Microsoft YaHei", "黑体", "宋体", "微软雅黑", sans-serif;
    /* font-size: 14px; */
    overflow-x: hidden;

}

/*清除浮动*/
.clearfix:before,
.clearfix:after {
    content: " ";
    display: inline-block;
    height: 0;
    clear: both;
    visibility: hidden;
}

.clearfix {
    *zoom: 1;
}

body {
    overflow-x: hidden;

    font-family: Microsoft YaHei;
}

.container {
    width: 15rem;
    margin: 0 auto;
}

.t-over-1 {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
}

.t-over-2 {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
}

.t-over-3 {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    display: -webkit-box;
}

:root {
    --themeColor: #009291;
    --comthemeColor: #009291;
    --lineargradient: linear-gradient(90deg,
            rgba(4, 170, 173, 1) 0,
            rgba(4, 170, 173, 0.8) 0,
            rgba(26, 170, 201, 0.5) 50%,
            rgba(26, 170, 201, 0) 100%);
    --titleColor: #333333;
    --Contentcolor: #666666;
    --descColor: #999999;
    --f24: .24rem;
    --f18: .18rem;
    --f16: .16rem;
    --f14: .14rem;
    /* --f24: 24px;
    --f18: 18px;
    --f16: 16px;
    --f14: 14px; */
}